<template>
  <div class="container">
    <div class="row">
      <div class="col-md-3 align-self-center">
        <h1 class="mb-3">Experimente</h1>
      </div>
      <div class="col-md-8 my-3">
        <p>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
        </p>
      </div>
    </div>
  </div>
  
</template>

<script>

export default {
    name: "Termos",
     components: {
    },
    data: () => ({
        links: [
        ]
    }),
    methods: {
    },
    async created() {
        
    },
    computed: {
    }
};
</script>
